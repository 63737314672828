import gql from 'graphql-tag';

export const ADD_REPORT = gql`
  mutation AddReport($report: reportInput) {
    addReport(input: $report) {
      id
      from
      userId
      adsTitle
      sid
      reason
      extra
    }
  }
`;
