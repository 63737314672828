const AVATAR_MEDIUM = 112;

const AVATAR_SMALL = 40;

const LARGE = 1200;

const MEDIUM = 750;

const SMALL_H = 210;
const SMALL_W = 265;

const SMALL = SMALL_H;

const filterBySize = size => element => {
  if (size === AVATAR_MEDIUM || size === AVATAR_SMALL) {
    return element.height === size || element.width === size;
  } else if (size !== SMALL) {
    return element.width === size;
  } else {
    return element.height === SMALL_H || element.width === SMALL_W;
  }
};

const getFilename = str => {
  const pathname = new URL(str).pathname;
  const file = pathname
    .split('/')
    .pop()
    .split('%2F')
    .pop();

  // handle space in filename
  return file.replace(/%20/g, ' ');
};

const getPhoto = (photo, size = AVATAR_SMALL) => {
  const { contentUrl, encodingFormat, height, thumbnail, width } = photo;

  let img = {
    contentUrl,
    encodingFormat,
    height,
    width,
  };

  // get thumbnail
  const thumbnails = thumbnail.filter(filterBySize(size));
  if (thumbnails && thumbnails.length > 0) {
    const webpType = 'image/webp';

    // get webp
    const imgThumbnail = thumbnails.find(
      element => element.encodingFormat !== webpType
    );
    const webpThumbnail = thumbnails.find(
      element => element.encodingFormat === webpType
    );

    if (imgThumbnail) {
      img = {
        ...img,
        thumbnail: {
          contentUrl: imgThumbnail.contentUrl,
          encodingFormat: imgThumbnail.encodingFormat,
          height: imgThumbnail.height,
          width: imgThumbnail.width,
        },
      };
    }

    if (webpThumbnail) {
      img.thumbnail.source = (
        <source srcSet={webpThumbnail.contentUrl} type={webpType} />
      );
    }
  }

  return img;
};

const getPhotos = (media = [], photos = [], size = SMALL) => {
  if (media === null) return [];

  return media.map(image => {
    if (photos && photos.length > 0) {
      const filename = getFilename(image);

      // find in image in photos
      const photo = photos.find(element => element.name === filename);
      if (photo) {
        return getPhoto(photo, size);
      }
    }

    return {
      contentUrl: image,
    };
  });
};

export { AVATAR_MEDIUM, AVATAR_SMALL, LARGE, MEDIUM, SMALL };
export { getFilename, getPhoto };
export default getPhotos;
